import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";

import { GatsbyImage } from "gatsby-plugin-image";

const NotFoundPage = (props) => {
  const { t } = useTranslation();
  return (
    <div className='bg-neutral-50 text-neutral-600'>
      <Layout>
        <div className='pt-14 sm:gap-14 grow-404-div flex flex-col items-center justify-center gap-10'>
          <div className='sm:-mt-20 sm:gap-5 gap flex flex-col items-center'>
            <h1 className='sm:text-5xl text-3xl font-bold'>404</h1>
            <h1>{t("TR_404")}</h1>
          </div>
          <div className='relative'>
            <div className='animate-bounce right-16 -top-5 sm:w-8 absolute w-6 h-auto'>
              <GatsbyImage
                image={props.data.question.childImageSharp.gatsbyImageData}
                alt='question mark'
                className='w-full h-auto'
              />
            </div>
            <GatsbyImage
              image={props.data.frog.childImageSharp.gatsbyImageData}
              alt='not found toad'
              className='w-60 sm:w-80'
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    frog: file(relativePath: { regex: "/404-sapo/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    question: file(relativePath: { regex: "/404-question/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export const Head = () => (
  <>
    <title>404 | CADT</title>
  </>
);
